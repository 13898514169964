import classNames from 'classnames';
import styled from 'styled-components';

import crest from 'assets/user/crest.png';
import { Horizontal } from 'atoms/layout/flex';

import ResponsiveImg from '../ResponsiveImg';

const Wrapper = styled.div`
  width: 100%;
  aspect-ratio: 1;
  border: 1px solid var(--c-neutral-100);
  border-radius: 50%;
  &.common {
    border-color: var(--c-collections-common);
  }
  &.limited {
    border-color: var(--c-collection-limited);
  }
  &.rare {
    border-color: var(--c-collection-rare);
  }
  &.super_rare {
    border-color: var(--c-collection-superRare);
  }
  &.unique {
    border-color: var(--c-collection-unique);
  }
`;

const Avatar = styled(Horizontal).attrs({ gap: 0, center: true })`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px var(--c-neutral-100);
  overflow: hidden;
`;

const Img = styled(ResponsiveImg)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`;

type Props = {
  name?: string;
  avatarUrl?: string | null;
  scarcity?: string;
  className?: string;
};

export const ScarcityAvatar = ({
  avatarUrl,
  name,
  scarcity,
  className,
}: Props) => {
  return (
    <Wrapper className={classNames(scarcity, className)}>
      <Avatar>
        <Img src={avatarUrl || crest} alt={name || ''} cropWidth={40} />
      </Avatar>
    </Wrapper>
  );
};
