import { TypedDocumentNode, gql } from '@apollo/client';

import FlagAvatar from 'components/country/FlagAvatar';
import { useIntlContext } from 'contexts/intl';
import { isType } from 'lib/gql';

import DumbClubAvatar from '../DumbClubAvatar';
import { TeamAvatar_team } from './__generated__/index.graphql';
import messages from './i18n';

type Props = {
  withTooltip?: boolean;
  className?: string;
  team: Nullable<TeamAvatar_team>;
  size?: number;
};

export const TeamAvatar = ({ team, withTooltip, className, size }: Props) => {
  const { formatMessage } = useIntlContext();
  if (isType(team, 'NationalTeam'))
    return (
      <FlagAvatar
        className={className}
        country={team.country}
        imageRes={64}
        withTooltip={withTooltip}
        size={size}
      />
    );
  return (
    <DumbClubAvatar
      className={className}
      club={
        team || {
          name: formatMessage(messages.noneName),
          avatarUrl: null,
        }
      }
      withTooltip={withTooltip}
      size={size}
    />
  );
};

TeamAvatar.fragments = {
  team: gql`
    fragment TeamAvatar_team on Team {
      ... on TeamInterface {
        slug
        name
        avatarUrl: pictureUrl(derivative: "avatar")
      }
      ... on NationalTeam {
        slug
        country {
          slug
          name
        }
      }
    }
  ` as TypedDocumentNode<TeamAvatar_team>,
};

export default TeamAvatar;
