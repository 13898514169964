import { unitMapping } from 'lib/style';

type Props = {
  title?: string;
  size?: keyof typeof unitMapping;
};
export const SwapBudget = ({ title, size = 2 }: Props) => {
  return (
    <svg
      width="1em"
      height="1em"
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={title}
    >
      <path
        d="M6.55662 0.37669C7.44979 -0.125563 8.55021 -0.125563 9.44338 0.37669L14.0566 2.97086C14.9498 3.47312 15.5 4.40132 15.5 5.40583V10.5942C15.5 11.5987 14.9498 12.5269 14.0566 13.0291L9.44338 15.6233C8.55021 16.1256 7.44979 16.1256 6.55662 15.6233L1.94338 13.0291C1.05021 12.5269 0.5 11.5987 0.5 10.5942V5.40583C0.5 4.40132 1.05021 3.47312 1.94338 2.97086L6.55662 0.37669Z"
        fill="url(#paint0_linear_12735_56438)"
      />
      <path
        d="M9.52765 6.50018L10.4705 6.50018L13.2989 9.3286L10.4705 12.157H9.52765V9.99527H8.03255V8.66194L9.52765 8.66194V6.50018Z"
        fill="white"
      />
      <path
        d="M6.47046 9.49035L5.52765 9.49035L2.69922 6.66192L5.52765 3.8335L6.47045 3.8335V5.99526L7.96555 5.99526V7.32859L6.47045 7.32859L6.47046 9.49035Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12735_56438"
          x1="2.6967"
          y1="2.34315"
          x2="13.9869"
          y2="12.9277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0066FF" />
          <stop offset="1" stopColor="#0051CC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
