import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { OverlapItems } from 'atoms/ui/OverlapItems';
import { ScarcityAvatar } from 'atoms/ui/ScarcityAvatar';
import { unitMapping } from 'lib/style';

const Root = styled.div`
  height: var(--size);
`;
const Avatar = styled(ScarcityAvatar)`
  width: var(--size);
`;
const AvatarTotalCount = styled(Horizontal).attrs({ center: true })`
  aspect-ratio: 1;
  width: var(--size);
  border-radius: 50%;
  border: 1px solid var(--c-neutral-400);
  background-color: var(--c-neutral-200);
  color: var(--c-neutral-900);
  font-size: var(--unit);
`;

type AvatarProps = {
  slug: string;
  name?: string;
  avatarUrl?: string | null;
  avatar?: ReactNode;
  scarcity?: string;
};

export type Props = {
  avatars?: AvatarProps[];
  additionalPlayersCount?: number;
  size?: keyof typeof unitMapping;
};

export const OverlappingAvatars = ({
  avatars = [],
  additionalPlayersCount,
  size = 2.5,
}: Props) => {
  return (
    <Root style={{ '--size': unitMapping[size] } as CSSProperties}>
      <OverlapItems>
        {avatars.map(avatar => (
          <Avatar key={avatar.slug} {...avatar} />
        ))}
        {(additionalPlayersCount || 0) > 0 && (
          <AvatarTotalCount>+{additionalPlayersCount}</AvatarTotalCount>
        )}
      </OverlapItems>
    </Root>
  );
};
