import { Navigate, generatePath } from 'react-router-dom';

import { Rarity } from '@sorare/core/src/__generated__/globalTypes';
import { FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE } from '@sorare/core/src/constants/__generated__/routes';

const InventoryPage = () => {
  return (
    <Navigate
      replace
      to={generatePath(FOOTBALL_PLAY_INVENTORY_REVEALABLE_CARDS_TYPE, {
        type: Rarity.limited,
      })}
    />
  );
};

export default InventoryPage;
