import { faStars } from '@fortawesome/pro-solid-svg-icons';
import styled, { css } from 'styled-components';

import {
  CustomRewardExperience,
  IRLPrizeType,
  Rarity,
} from '@sorare/core/src/__generated__/globalTypes';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Fiat } from '@sorare/core/src/atoms/icons/Fiat';
import { NewScarcityIcon } from '@sorare/core/src/atoms/icons/ScarcityIcon';
import { ArenaTicket } from '@sorare/core/src/atoms/icons/rivals/branded/ArenaTicket';
import { FRONTEND_ASSET_HOST } from '@sorare/core/src/constants/assets';
import {
  RewardType,
  SimpleReward,
  shardsIconUrl,
} from '@sorare/core/src/lib/rewards';
import toSnakeCase from '@sorare/core/src/lib/toSnakeCase';

const StyledImg = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
`;
const forcedComponentStyle = css`
  width: 90% !important;
  height: unset !important;
  aspect-ratio: 1/1;
`;
const StyledFiat = styled(Fiat)`
  ${forcedComponentStyle}
`;
const StyledCard = styled(NewScarcityIcon)`
  ${forcedComponentStyle}
`;
const StyledArenaTicket = styled(ArenaTicket)`
  ${forcedComponentStyle}
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  ${forcedComponentStyle}
`;

type Props = {
  simpleReward: SimpleReward;
};

const getRewardImage = (type: SimpleReward['type'], rarity: Rarity | null) => {
  switch (type) {
    case RewardType.COIN:
      return 'coin';
    case RewardType.CREDIT:
      return 'credit';
    case RewardType.PROBABILISTIC_BUNDLE:
      return `box-${toSnakeCase(rarity || Rarity.common)}`;
    case CustomRewardExperience.JERSEY:
    case IRLPrizeType.MEMORABILIA:
      return 'jersey';
    case CustomRewardExperience.MERCH:
    case IRLPrizeType.GIFT_CARD:
    case IRLPrizeType.TV_SUBSCRIPTION:
      return 'merch';
    case CustomRewardExperience.TICKET:
    case IRLPrizeType.TICKETS:
      return 'tickets';
    default:
      return '';
  }
};

const REWARDS_ASSETS_URL = `${FRONTEND_ASSET_HOST}/football/rewards`;

export const RewardOverviewImage = ({ simpleReward }: Props) => {
  const { type, rarity } = simpleReward;

  switch (type) {
    case RewardType.CASH:
      return <StyledFiat />;
    case RewardType.CARD:
      return <StyledCard rarity={rarity || Rarity.common} />;
    case RewardType.ARENA_TICKET:
      return <StyledArenaTicket />;
    case RewardType.SHARD:
      return <StyledImg src={shardsIconUrl(rarity || Rarity.common)} alt="" />;
    case CustomRewardExperience.EVENT:
      return <StyledFontAwesomeIcon icon={faStars} />;
    default:
      return (
        <StyledImg
          src={`${REWARDS_ASSETS_URL}/icons/${getRewardImage(type, rarity)}.png`}
          alt={type}
        />
      );
  }
};
