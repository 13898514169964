import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
import { StrokedText } from 'atoms/typography/StrokedText';
import { rivals } from 'lib/glossary';

import arenaTicket from './assets/arenaTicket.svg';
import freeArenaTicket from './assets/freeArenaTicket.svg';

const Root = styled(Horizontal).attrs({ center: true })`
  position: relative;
  isolation: isolate;
`;
const TiltedImg = styled.img`
  transform: rotate(10deg);
  width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
const AbsoluteImg = styled.img`
  position: absolute;
  width: 100%;
  transform: translateX(-30%) translateY(10%) rotate(-5deg);
  z-index: -1;
`;
const CountWrapper = styled.div`
  position: absolute;
  right: calc(-1 * var(--half-unit));
  bottom: calc(-1 * var(--half-unit));
  z-index: 1;
`;

type Props = {
  width?: number;
  count?: number;
  double?: boolean;
  free?: boolean;
  className?: string;
};
export const ArenaTicket = ({
  count,
  width = 12,
  double,
  free,
  className,
}: Props) => {
  const { formatMessage } = useIntl();

  const img = free ? freeArenaTicket : arenaTicket;

  return (
    <Root style={{ width: `${width}px` }} className={className}>
      {double && <AbsoluteImg src={img} />}
      <TiltedImg
        src={img}
        alt={
          typeof count === 'number' && count > 1
            ? formatMessage(rivals.arenaTickets)
            : formatMessage(rivals.arenaTicket)
        }
      />
      {typeof count === 'number' && (
        <CountWrapper>
          <StrokedText yOffset={1} strokeWidth={2}>
            <LabelM bold>{count}</LabelM>
          </StrokedText>
        </CountWrapper>
      )}
    </Root>
  );
};
